import React, { Fragment } from 'react';
import { dataFields } from '../../config/data-fields-config';
import NumericDataEntry from '../data-components/numeric-data-entry';
import withCopyEdit from '../data-container';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import InfoBox from '../../components/info-box';
/**
* Retrofit & Condition view/edit section
*/
const RetrofitConditionView = (props) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const subcat = queryParameters.get("sc");
    const currentYear = new Date().getFullYear();
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Retrofit history", collapsed: subcat == null || subcat != "3" },
            React.createElement(NumericDataEntry, { slug: 'age_retrofit_date', title: dataFields.age_retrofit_date.title, value: props.building.age_retrofit_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
            React.createElement(Verification, { slug: "age_retrofit_date", allow_verify: props.user !== undefined && props.building.age_retrofit_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_retrofit_date"), user_verified_as: props.user_verified.age_retrofit_date, verified_count: props.building.verified.age_retrofit_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_retrofit_date_source_type.title, slug: "age_retrofit_date_source_type", value: props.building.age_retrofit_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_type.tooltip, options: dataFields.age_retrofit_date_source_type.items, placeholder: dataFields.age_retrofit_date_source_type.example }),
            (props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[0] ||
                props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[1] ||
                props.building.age_retrofit_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_retrofit_date_source_links.title, slug: "age_retrofit_date_source_links", value: props.building.age_retrofit_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Condition", collapsed: subcat == null || subcat != "7" },
            React.createElement(InfoBox, { type: 'info' },
                "This section is under development. Please let us know your suggestions on the ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-core/discussions" }, "discussion forum"),
                "! (external link - save your edits first)"))));
};
const RetrofitConditionContainer = withCopyEdit(RetrofitConditionView);
export default RetrofitConditionContainer;
