import React, { Fragment } from 'react';
import { buildingUserFields, dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import withCopyEdit from '../data-container';
import Verification from '../data-components/verification';
import InfoBox from '../../components/info-box';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import { LogicalDataEntry } from '../data-components/logical-data-entry/logical-data-entry';
import { Category } from '../../config/categories-config';
import PlanningDataOfficialDataEntry from '../data-components/planning-data-entry';
import NumericDataEntry from '../data-components/numeric-data-entry';
import { useDisplayPreferences } from '../../displayPreferences-context';
import UserOpinionEntry from '../data-components/user-opinion-data-entry';
import { Link } from 'react-router-dom';
import NumericDataEntryWithFormattedLink from '../data-components/numeric-data-entry-with-formatted-link';
const currentTimestamp = new Date().valueOf();
const milisecondsInYear = 1000 * 60 * 60 * 24 * 365;
// there is already "parseDate" in helpers
// but it is using timestamp as input, while this one
// uses lower accuracy (as actual data is using the same accuracy)
function parseDateSpecifiedWithDailyAccuracy(isoUtcDate) {
    const [year, month, day] = isoUtcDate.match(/^(\d{4})-(\d\d)-(\d\d)$/)
        .splice(1)
        .map(x => parseInt(x, 10));
    return new Date(Date.UTC(year, month - 1, day));
}
function isArchived(item) {
    const decisionDate = item.decision_date;
    if (decisionDate != null) {
        if ((currentTimestamp - parseDateSpecifiedWithDailyAccuracy(decisionDate).valueOf()) > milisecondsInYear) {
            return true;
        }
    }
    if (item.registered_with_local_authority_date != null) {
        if ((currentTimestamp - parseDateSpecifiedWithDailyAccuracy(item.registered_with_local_authority_date).valueOf()) > milisecondsInYear) {
            return true;
        }
    }
    return false;
}
/**
* Age & History view/edit section
*/
const AgeHistoryView = (props) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const subcat = queryParameters.get("sc");
    const switchToExpectedApplicationMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_expected_planning_application_total');
    };
    const switchToBuildingProtectionMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('planning_combined');
    };
    const switchToAllPlanningApplicationsMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('planning_applications_status_all');
    };
    const switchToLastTwelveMonthsMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('planning_applications_status_recent');
    };
    const switchToLastThirtyDaysMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('planning_applications_status_very_recent');
    };
    const switchToLocalSignificanceMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_local_significance_total');
    };
    const { housing, housingSwitchOnClick, creative, creativeSwitchOnClick, vista, vistaSwitchOnClick, parcel, parcelSwitchOnClick, conservation, conservationSwitchOnClick, darkLightTheme } = useDisplayPreferences();
    const communityLinkUrl = `/${props.mode}/${Category.Community}/${props.building.building_id}`;
    const currentYear = new Date().getFullYear();
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Planning", collapsed: subcat == null || (subcat != "1" && subcat != "2" && subcat != "3") },
            React.createElement(DataEntryGroup, { name: "Current planning applications", collapsed: subcat == null || (subcat != "1" && subcat != "2" && subcat != "3") },
                React.createElement(DataEntryGroup, { name: "Official data", collapsed: subcat == null || subcat != "1" },
                    React.createElement(InfoBox, null,
                        "This section provides data on active applications. We define these as applications with any activity in the last year.",
                        React.createElement("br", null),
                        "To comment on an application follow the application link if provided, or visit the relevant local authority's planning page."),
                    props.mapColourScale != "planning_applications_status_all" ?
                        React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button sub-subcategory-button`, onClick: switchToAllPlanningApplicationsMapStyle }, 'Click to view official planning application data')
                        :
                            React.createElement(React.Fragment, null),
                    props.building.planning_data ?
                        React.createElement(PlanningDataOfficialDataEntry, { shownData: props.building.planning_data.filter(item => isArchived(item) == false), messageOnMissingData: props.building.planning_data.length > 0 ?
                                "Only past application data is currently available for this site"
                                :
                                    "No live planning data are currently available for this building from the Planning London Datahub." })
                        : React.createElement(React.Fragment, null)),
                React.createElement(DataEntryGroup, { name: "Year of completion", collapsed: subcat == null || subcat != "2" },
                    React.createElement(LogicalDataEntry, { slug: 'planning_crowdsourced_site_completion_status', title: dataFields.planning_crowdsourced_site_completion_status.title, tooltip: dataFields.planning_crowdsourced_site_completion_status.tooltip, value: props.building.planning_crowdsourced_site_completion_status, copy: props.copy, onChange: props.onChange, mode: props.mode }),
                    React.createElement(Verification, { slug: "planning_crowdsourced_site_completion_status", allow_verify: props.user !== undefined && props.building.planning_crowdsourced_site_completion_status !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_crowdsourced_site_completion_status"), user_verified_as: props.user_verified.planning_crowdsourced_site_completion_status, verified_count: props.building.verified.planning_crowdsourced_site_completion_status }),
                    props.building.planning_crowdsourced_site_completion_status == null ? React.createElement(React.Fragment, null) :
                        React.createElement(React.Fragment, null,
                            React.createElement(NumericDataEntry, { title: dataFields.planning_crowdsourced_site_completion_year.title, slug: "planning_crowdsourced_site_completion_year", value: props.building.planning_crowdsourced_site_completion_year, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.planning_crowdsourced_site_completion_year.tooltip }),
                            React.createElement(Verification, { slug: "planning_crowdsourced_site_completion_year", allow_verify: props.user !== undefined && props.building.planning_crowdsourced_site_completion_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_crowdsourced_site_completion_year"), user_verified_as: props.user_verified.planning_crowdsourced_site_completion_year, verified_count: props.building.verified.planning_crowdsourced_site_completion_year }),
                            React.createElement(SelectDataEntry, { title: dataFields.planning_crowdsourced_site_completion_source_type.title, slug: "planning_crowdsourced_site_completion_source_type", value: props.building.planning_crowdsourced_site_completion_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.planning_crowdsourced_site_completion_source_type.tooltip, options: dataFields.planning_crowdsourced_site_completion_source_type.items, placeholder: dataFields.planning_crowdsourced_site_completion_source_type.example }),
                            (props.building.planning_crowdsourced_site_completion_source_type == dataFields.planning_crowdsourced_site_completion_source_type.items[0] ||
                                props.building.planning_crowdsourced_site_completion_source_type == dataFields.planning_crowdsourced_site_completion_source_type.items[1] ||
                                props.building.planning_crowdsourced_site_completion_source_type == null) ? React.createElement(React.Fragment, null) :
                                React.createElement(React.Fragment, null,
                                    React.createElement(MultiDataEntry, { title: dataFields.planning_crowdsourced_site_completion_source_links.title, slug: "planning_crowdsourced_site_completion_source_links", value: props.building.planning_crowdsourced_site_completion_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.planning_crowdsourced_site_completion_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })))),
                React.createElement(DataEntryGroup, { name: "Incomplete/missing data", collapsed: subcat == null || subcat != "3" },
                    React.createElement(LogicalDataEntry, { slug: 'planning_missing_data', title: dataFields.planning_missing_data.title, tooltip: dataFields.planning_missing_data.tooltip, value: props.building.planning_missing_data, copy: props.copy, onChange: props.onChange, mode: props.mode }),
                    React.createElement(Verification, { slug: "planning_missing_data", allow_verify: props.user !== undefined && props.building.planning_missing_data !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_missing_data"), user_verified_as: props.user_verified.planning_missing_data, verified_count: props.building.verified.planning_missing_data }),
                    props.building.planning_missing_data == null ? React.createElement(React.Fragment, null) :
                        React.createElement(React.Fragment, null,
                            React.createElement(MultiDataEntry, { title: dataFields.planning_missing_data_links.title, slug: "planning_missing_data_links", value: props.building.planning_missing_data_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.planning_missing_data_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
                    React.createElement(InfoBox, null, "If you feel there are incorrect or missing data relating to this building please contact: planningdata@London.gov.uk"))),
            React.createElement(DataEntryGroup, { name: "Past applications", collapsed: subcat == null || subcat != "4" },
                React.createElement(InfoBox, null, "This section provides data on past applications where available from the GLA, including those with no decision in over a year"),
                props.mapColourScale != "planning_applications_status_recent" ?
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button no-left-margin`, onClick: switchToLastTwelveMonthsMapStyle }, 'Click to view planning applications from the last 12 months')
                    :
                        React.createElement(React.Fragment, null),
                props.mapColourScale != "planning_applications_status_very_recent" ?
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button no-left-margin`, onClick: switchToLastThirtyDaysMapStyle }, 'Click to view planning applications from the last 30 days')
                    :
                        React.createElement(React.Fragment, null),
                props.building.planning_data ?
                    React.createElement(PlanningDataOfficialDataEntry, { shownData: props.building.planning_data.filter(item => isArchived(item)), messageOnMissingData: props.building.planning_data.length > 0 ?
                            "Only current application data is currently available for this site"
                            :
                                "No live planning data are currently available for this building from the Planning London Datahub." })
                    : React.createElement(React.Fragment, null)),
            React.createElement(DataEntryGroup, { name: "Possible future applications", collapsed: subcat == null || subcat != "5" },
                React.createElement(InfoBox, { type: 'info' },
                    "Click and colour buildings here if you think they may be subject to a future planning application involving demolition. To add your opinion on how well this building works, please also visit the ",
                    React.createElement(Link, { to: communityLinkUrl }, "Community"),
                    " section."),
                props.mapColourScale != "community_expected_planning_application_total" ?
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToExpectedApplicationMapStyle }, 'Click to view possible locations of future applications')
                    :
                        React.createElement(React.Fragment, null),
                React.createElement(UserOpinionEntry, { slug: 'community_expected_planning_application', title: buildingUserFields.community_expected_planning_application.title, tooltip: buildingUserFields.community_expected_planning_application.tooltip, userValue: props.building.community_expected_planning_application, onChange: props.onSaveChange, mode: props.mode }),
                React.createElement(InfoBox, { type: 'warning' }, "Further improvements to this feature are currently being made."),
                React.createElement(UserOpinionEntry, { slug: 'community_local_significance', title: buildingUserFields.community_local_significance.title, tooltip: buildingUserFields.community_local_significance.tooltip, userValue: props.building.community_local_significance, onChange: props.onSaveChange, mode: props.mode }),
                (props.mapColourScale != "community_local_significance_total") ?
                    React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToLocalSignificanceMapStyle }, 'Click to show buildings of local interest.')
                    :
                        React.createElement(React.Fragment, null)),
            React.createElement(DataEntryGroup, { name: "Planning zones", collapsed: subcat == null || subcat != "6" },
                React.createElement(InfoBox, null,
                    "To view planning zone data for London click the buttons below. You may need to ",
                    React.createElement("u", null, "zoom out"),
                    ". Information on whether a specific building is in a zone will be added automatically in future."),
                React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                    React.createElement("i", null,
                        "Data in this section comes from ",
                        React.createElement("a", { href: "https://www.london.gov.uk/programmes-strategies/planning/digital-planning/planning-london-datahub" }, "the Greater London Authority's Planning London Datahub"),
                        ". Please check the original GLA source when using for planning purposes.",
                        React.createElement("br", null),
                        "Specific sources are mentioned in the footer of map for currently enabled layers.")),
                React.createElement(LogicalDataEntry, { slug: 'planning_housing_zone', title: dataFields.planning_housing_zone.title, tooltip: dataFields.planning_housing_zone.tooltip, value: props.building.planning_housing_zone, copy: props.copy, onChange: props.onChange, mode: props.mode, disabled: true }),
                React.createElement("button", { className: `map-switcher-inline ${housing}-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: housingSwitchOnClick }, (housing === 'enabled') ? 'Click to hide Housing Zones' : 'Click to see Housing Zones mapped'),
                React.createElement(LogicalDataEntry, { slug: 'planning_enterprise_zone', title: dataFields.planning_enterprise_zone.title, tooltip: dataFields.planning_enterprise_zone.tooltip, value: props.building.planning_enterprise_zone, copy: props.copy, onChange: props.onChange, mode: props.mode, disabled: true }),
                React.createElement("button", { className: `map-switcher-inline ${creative}-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: creativeSwitchOnClick }, (creative === 'enabled') ? 'Click to hide Creative Enterprise Zones' : 'Click to see Creative Enterprise Zones'),
                React.createElement(LogicalDataEntry, { slug: 'planning_protected_vista', title: dataFields.planning_protected_vista.title, tooltip: dataFields.planning_protected_vista.tooltip, value: props.building.planning_protected_vista, copy: props.copy, onChange: props.onChange, mode: props.mode, disabled: true }),
                React.createElement("button", { className: `map-switcher-inline ${vista}-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: vistaSwitchOnClick }, (vista === 'enabled') ? 'Click to hide Protected Vistas' : 'Click to see Protected Vistas')),
            React.createElement(DataEntryGroup, { name: "Land ownership type", collapsed: subcat == null || subcat != "8" },
                React.createElement(InfoBox, null, "This section is designed to provide information on land parcels and their ownership type. Can you help us collect this information?"),
                React.createElement(SelectDataEntry, { slug: 'community_public_ownership', title: dataFields.community_public_ownership.title, value: props.building.community_public_ownership, options: dataFields.community_public_ownership.items, tooltip: dataFields.community_public_ownership.tooltip, onChange: props.onChange, mode: props.mode, copy: props.copy }),
                React.createElement(Verification, { slug: "community_public_ownership", allow_verify: props.user !== undefined && props.building.community_public_ownership !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("community_public_ownership"), user_verified_as: props.user_verified.community_public_ownership, verified_count: props.building.verified.community_public_ownership }),
                React.createElement(SelectDataEntry, { title: dataFields.community_public_ownership_source_type.title, slug: "community_public_ownership_source_type", value: props.building.community_public_ownership_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.community_public_ownership_source_type.tooltip, options: dataFields.community_public_ownership_source_type.items, placeholder: dataFields.community_public_ownership_source_type.example }),
                (props.building.community_public_ownership_source_type == dataFields.community_public_ownership_source_type.items[0] ||
                    props.building.community_public_ownership_source_type == dataFields.community_public_ownership_source_type.items[1] ||
                    props.building.community_public_ownership_source_type == null) ? React.createElement(React.Fragment, null) :
                    React.createElement(React.Fragment, null,
                        React.createElement(MultiDataEntry, { slug: 'community_public_ownership_sources', title: dataFields.community_public_ownership_sources.title, tooltip: dataFields.community_public_ownership_sources.tooltip, isUrl: true, placeholder: 'https://...', editableEntries: true, value: props.building.community_public_ownership_sources, onChange: props.onChange, mode: props.mode, copy: props.copy })),
                React.createElement("hr", null),
                React.createElement(DataEntry, { title: dataFields.size_parcel_geometry.title, slug: "size_parcel_geometry", value: props.building.size_parcel_geometry, mode: props.mode, onChange: props.onChange, tooltip: dataFields.size_parcel_geometry.tooltip, placeholder: "https://...", isUrl: true }),
                React.createElement("button", { className: `map-switcher-inline ${parcel}-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: parcelSwitchOnClick }, (parcel === 'enabled') ? 'Click to hide sample land parcel data' : 'Click to show sample land parcel data'))),
        React.createElement(DataEntryGroup, { name: "Heritage assets and building protection", collapsed: subcat == null || subcat != "7" },
            React.createElement(InfoBox, null, "Help us produce the most accurate map possible for London's designated/protected buildings. Please add data if missing or click \"Verify\" where entries are correct."),
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement("i", null,
                    React.createElement("div", null,
                        React.createElement("u", null, "Disclaimer"),
                        ":  Data for designated heritage assets has been accessed from the ",
                        React.createElement("a", { href: "https://historicengland.org.uk/listing/the-list/" }, "National Heritage List for England"),
                        ". Source information for Conservation Area data can be accessed ",
                        React.createElement("a", { href: "http://www.bedfordpark.net/leo/planning/" }, "here"),
                        ". Please note all data should be double checked against official sources where used for planning purposes'."))),
            props.mapColourScale != "planning_combined" ?
                React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToBuildingProtectionMapStyle }, 'Click to see individual protected buildings mapped')
                :
                    React.createElement(React.Fragment, null),
            React.createElement("button", { className: `map-switcher-inline ${conservation}-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: conservationSwitchOnClick }, (conservation === 'enabled') ? 'Click to hide Conservation Areas' : 'Click to see Conservation Areas'),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_heritage_at_risk', title: dataFields.planning_heritage_at_risk.title, tooltip: dataFields.planning_heritage_at_risk.tooltip, value: props.building.planning_heritage_at_risk, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_heritage_at_risk", allow_verify: props.user !== undefined && props.building.planning_heritage_at_risk !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_heritage_at_risk"), user_verified_as: props.user_verified.planning_heritage_at_risk, verified_count: props.building.verified.planning_heritage_at_risk }),
            (props.building.planning_heritage_at_risk == null || props.building.planning_heritage_at_risk == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(DataEntry, { title: dataFields.planning_heritage_at_risk_url.title, slug: "planning_heritage_at_risk_url", value: props.building.planning_heritage_at_risk_url, tooltip: dataFields.planning_heritage_at_risk_url.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, placeholder: "Please add relevant link here", isUrl: true }),
                    React.createElement(Verification, { slug: "planning_heritage_at_risk_url", allow_verify: props.user !== undefined && props.building.planning_heritage_at_risk_url !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_heritage_at_risk_url"), user_verified_as: props.user_verified.planning_heritage_at_risk_url, verified_count: props.building.verified.planning_heritage_at_risk_url })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_world_heritage_site', title: dataFields.planning_world_heritage_site.title, tooltip: dataFields.planning_world_heritage_site.tooltip, value: props.building.planning_world_heritage_site, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_world_heritage_site", allow_verify: props.user !== undefined && props.building.planning_world_heritage_site !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_world_heritage_site"), user_verified_as: props.user_verified.planning_world_heritage_site, verified_count: props.building.verified.planning_world_heritage_site }),
            (props.building.planning_world_heritage_site == null || props.building.planning_world_heritage_site == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(NumericDataEntryWithFormattedLink, { title: dataFields.planning_world_list_id.title, slug: "planning_world_list_id", value: props.building.planning_world_list_id, tooltip: dataFields.planning_world_list_id.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, placeholder: "add ID here", linkTargetFunction: (id) => { return "https://whc.unesco.org/en/list/" + id; }, linkDescriptionFunction: (id) => { return "UNESCO Link"; } }),
                    React.createElement(Verification, { slug: "planning_world_list_id", allow_verify: props.user !== undefined && props.building.planning_world_list_id !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_world_list_id"), user_verified_as: props.user_verified.planning_world_list_id, verified_count: props.building.verified.planning_world_list_id })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_listed', title: dataFields.planning_listed.title, tooltip: dataFields.planning_listed.tooltip, value: props.building.planning_listed, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_listed", allow_verify: props.user !== undefined && props.building.planning_listed !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_listed"), user_verified_as: props.user_verified.planning_listed, verified_count: props.building.verified.planning_listed }),
            (props.building.planning_listed == null || props.building.planning_listed == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(SelectDataEntry, { title: dataFields.planning_list_grade.title, slug: "planning_list_grade", value: props.building.planning_list_grade, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.planning_list_grade.tooltip, options: dataFields.planning_list_grade.items }),
                    React.createElement(NumericDataEntryWithFormattedLink, { title: dataFields.planning_list_id.title, slug: "planning_list_id", value: props.building.planning_list_id, tooltip: dataFields.planning_list_id.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, placeholder: "add ID here", linkTargetFunction: (id) => { return "https://historicengland.org.uk/listing/the-list/list-entry/" + id; }, linkDescriptionFunction: (id) => { return "Historic England Link"; } })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_local_list', title: dataFields.planning_local_list.title, tooltip: dataFields.planning_local_list.tooltip, value: props.building.planning_local_list, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_local_list", allow_verify: props.user !== undefined && props.building.planning_local_list !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_local_list"), user_verified_as: props.user_verified.planning_local_list, verified_count: props.building.verified.planning_local_list }),
            (props.building.planning_local_list == null || props.building.planning_local_list == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(DataEntry, { title: dataFields.planning_local_list_url.title, slug: "planning_local_list_url", value: props.building.planning_local_list_url, tooltip: dataFields.planning_local_list_url.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, isUrl: true, placeholder: "Please add relevant link here" }),
                    React.createElement(Verification, { slug: "planning_local_list_url", allow_verify: props.user !== undefined && props.building.planning_local_list_url !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_local_list_url"), user_verified_as: props.user_verified.planning_local_list_url, verified_count: props.building.verified.planning_local_list_url })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_in_conservation_area', title: dataFields.planning_in_conservation_area.title, tooltip: dataFields.planning_in_conservation_area.tooltip, value: props.building.planning_in_conservation_area, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_in_conservation_area", allow_verify: props.user !== undefined && props.building.planning_in_conservation_area !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_in_conservation_area"), user_verified_as: props.user_verified.planning_in_conservation_area, verified_count: props.building.verified.planning_in_conservation_area }),
            (props.building.planning_in_conservation_area == null || props.building.planning_in_conservation_area == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(DataEntry, { title: dataFields.planning_in_conservation_area_url.title, slug: "planning_in_conservation_area_url", value: props.building.planning_in_conservation_area_url, tooltip: dataFields.planning_in_conservation_area_url.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, isUrl: true, placeholder: "Please add CA appraisal link here" }),
                    props.building.planning_in_conservation_area_url === "" ? "Our CA map records this building as not being within a CA. To help us verify this, please click ‘verify’ or, if info is incorrect, please add the local authority’s CA appraisal link." : "",
                    props.building.planning_in_conservation_area_url === "identified as listed: please replace with links" ? "Our CA map records this building as being within a CA. To help us verify this information please add the local authority’s CA appraisal link and then click ‘verify’." : "",
                    React.createElement(Verification, { slug: "planning_in_conservation_area_url", allow_verify: props.user !== undefined && props.building.planning_in_conservation_area_url !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_in_conservation_area_url"), user_verified_as: props.user_verified.planning_in_conservation_area_url, verified_count: props.building.verified.planning_in_conservation_area_url })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_in_apa', title: dataFields.planning_in_apa.title, tooltip: dataFields.planning_in_apa.tooltip, value: props.building.planning_in_apa, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_in_apa", allow_verify: props.user !== undefined && props.building.planning_in_apa !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_in_apa"), user_verified_as: props.user_verified.planning_in_apa, verified_count: props.building.verified.planning_in_apa }),
            (props.building.planning_in_apa == null || props.building.planning_in_apa == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(DataEntry, { title: dataFields.planning_in_apa_url.title, slug: "planning_in_apa_url", value: props.building.planning_in_apa_url, tooltip: dataFields.planning_in_apa_url.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, isUrl: true, placeholder: "Please add relevant link here" }),
                    React.createElement(Verification, { slug: "planning_in_apa_url", allow_verify: props.user !== undefined && props.building.planning_in_apa_url !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_in_apa_url"), user_verified_as: props.user_verified.planning_in_apa_url, verified_count: props.building.verified.planning_in_apa_url })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_scientific_interest', title: dataFields.planning_scientific_interest.title, tooltip: dataFields.planning_scientific_interest.tooltip, value: props.building.planning_scientific_interest, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_scientific_interest", allow_verify: props.user !== undefined && props.building.planning_scientific_interest !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_scientific_interest"), user_verified_as: props.user_verified.planning_scientific_interest, verified_count: props.building.verified.planning_scientific_interest }),
            (props.building.planning_scientific_interest == null || props.building.planning_scientific_interest == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.planning_scientific_interest_source_links.title, slug: "planning_scientific_interest_source_links", value: props.building.planning_scientific_interest_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.planning_scientific_interest_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            React.createElement(LogicalDataEntry, { slug: 'planning_historic_area_assessment', title: dataFields.planning_historic_area_assessment.title, tooltip: dataFields.planning_historic_area_assessment.tooltip, value: props.building.planning_historic_area_assessment, copy: props.copy, onChange: props.onChange, mode: props.mode }),
            React.createElement(Verification, { slug: "planning_historic_area_assessment", allow_verify: props.user !== undefined && props.building.planning_historic_area_assessment !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_historic_area_assessment"), user_verified_as: props.user_verified.planning_historic_area_assessment, verified_count: props.building.verified.planning_historic_area_assessment }),
            (props.building.planning_historic_area_assessment == null || props.building.planning_historic_area_assessment == false) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(DataEntry, { title: dataFields.planning_historic_area_assessment_url.title, slug: "planning_historic_area_assessment_url", value: props.building.planning_historic_area_assessment_url, tooltip: dataFields.planning_historic_area_assessment_url.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, isUrl: true, placeholder: "Please add relevant link here" }),
                    React.createElement(Verification, { slug: "planning_historic_area_assessment_url", allow_verify: props.user !== undefined && props.building.planning_historic_area_assessment_url !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("planning_historic_area_assessment_url"), user_verified_as: props.user_verified.planning_historic_area_assessment_url, verified_count: props.building.verified.planning_historic_area_assessment_url })))));
};
const AgeHistoryContainer = withCopyEdit(AgeHistoryView);
export default AgeHistoryContainer;
