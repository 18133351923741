import React from 'react';
import { NavLink } from 'react-router-dom';
import './category-link.css';
const CategoryLink = (props) => {
    let categoryLink = `/${props.mode}/${props.slug}`;
    if (props.building_id != undefined)
        categoryLink += `/${props.building_id}`;
    let className = "category-title";
    return (React.createElement(NavLink, { className: `category-link background-${props.slug}`, to: categoryLink, title: (props.inactive) ?
            'Coming soon… Click more info for details.'
            : 'View/Edit Map' },
        React.createElement("h3", { className: className }, props.title)));
};
export { CategoryLink };
